
import { ReactComponent as listIconSvg } from "../images/list.svg"
import { ReactComponent as UserSvg } from "../images/users-svgrepo-com.svg"
import { ReactComponent as ImagesSvg } from "../images/images-svgrepo-com.svg"

export function NavigationLinkData() {

  const navLinkData = [
    { link: `/dashboard`, label: "Dashboard", icon: listIconSvg },

    { link: `/users`, label: "Users", icon: UserSvg },

    { link: `/categories`, label: "Categories", icon: listIconSvg },

    { link: `/images`, label: "Images", icon: ImagesSvg },
         
    { link: `/publisher`, label: "Publishers", icon: ImagesSvg },

    { link: `/notification`, label: "Notification", icon: listIconSvg },

  ];

  return {
    navLinkData,
  };
}
