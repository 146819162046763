import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const api_url = process.env.REACT_APP_BACKEND_API_BASE_URL;

export const getSubCategoriesThunk = createAsyncThunk(
    'subcategory/all',
    async (body: any, { rejectWithValue }) => {
        debugger
        try {
            debugger
            const data = await axios.get(`${api_url}/subcategory/all?page=${body.page}&limit=${body.limit}`, { 'headers': { 'Authorization': `${body.token}` } })
            return data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const getAllActiveCategoriesThunk = createAsyncThunk(
    'active/categories',
    async (body: any, { rejectWithValue }) => {
        try {
            const data = await axios.get(`${api_url}/subcategory/activeCategories`, { 'headers': { 'Authorization': `${body.token}` } })
            return data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const addSubCategoryThunk = createAsyncThunk(
    "subcategory/create",
    async (body: any, { rejectWithValue }) => {
        try {
            let token = body.token;
            delete body.token
            const data = await axios.post(`${api_url}/subcategory/create`, body, { 'headers': { 'Authorization': `${token}` } })
            return data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const deleteSubCategoryThunk = createAsyncThunk(
    "subcategory/delete",
    async (body: any, { rejectWithValue }) => {
        try {
            const data = await axios.delete(`${api_url}/subcategory/delete/${body._id}`, { 'headers': { 'Authorization': `${body.token}` } })
            return data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

