import { imageSelector } from "Redux/Selectors/imageSelector";
import { addImageThunk } from "../../Redux/Thunks/imageThunk";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as DownArrowSvg } from "../../images/down-arrow-6402.svg";
import StringArrayInput from "../../components/Custom/StringArrayInput";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { packsSelector } from "../../Redux/Selectors/packsSelector";
import { addPackThunk, getPacksThunk } from "../../Redux/Thunks/specialPacks";
import MultiSelectDropDown from "../../components/Packs/MultiSelectDropDown";
import { publisherSelector } from "Redux/Selectors/publisherSelector";
import { addPublishersThunk } from "Redux/Thunks/publisherThunk";
import { Loader } from "rsuite";

export default function AddNew() {
    const dispatch = useDispatch();
    const { activeCategories, image } = useSelector(imageSelector);
    const { packs } = useSelector(packsSelector);
    const { publishers } = useSelector(publisherSelector);
    const [enteredHashTags, setEnteredHashTags] = useState([""]);
    const [options, setOptions] = useState([]);
    const [assignedPack, setAssignedPack] = useState([]);
    const navigate = useNavigate();
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(false);
    const [hdLoading, setHdLoading] = useState(false);

    const [form, setForm] = useState([
        {
            name: "",
            type: "",
            image_hd: "",
            image_low: "",
            image: "",
            category_id: "",
            publisher: "",
            title: "",
            hashtags: [],
            description: "",
            url: "",
            image_virent_id: "",
            price: "",
            _id: "",
        },
    ]);

    useEffect(() => {
        let token = checkLoginToken();
        let body = {
            token: token,
        };
        dispatch(getPacksThunk(body));
        // dispatch(addPackThunk(body));
        // dispatch(addPublishersThunk(body));
    }, []);
    const removeItem = (i) => {
        let newFormValues = form.filter((ele, index) =>
            index != i ? true : false
        );

        setForm(newFormValues);
    };
    const handleAddMore = () => {
        const newFormAdd = {
            name: "",
            type: "",
            image_hd: "",
            image_low: "",
            image: "",
            category_id: "",
            publisher: "",
            title: "",
            description: "",
            url: "",
            image_virent_id: "",
            price: "",
            // _id: ''
        };
        setForm([...form, newFormAdd]);
        console.log('form', form)
    };

    function logoutUser() {
        // dispatch(setIsLoggedIn());
        localStorage.removeItem("currentToken");
        localStorage.removeItem("userId");
        // navigate("/login")
    }
    function checkLoginToken() {
        let token = localStorage.getItem("currentToken");
        if (!token) {
            logoutUser();
        } else {
            return token;
        }
    }
   
    const handleChange = async (i, e) => {
        let token = checkLoginToken();
        const { name, value, type, files } = e.target;
        setEnteredHashTags([...enteredHashTags]);
        let newForm = [...form];
        let formData = new FormData();
        //  newForm[i].token = token;
        newForm[i].hashtags = enteredHashTags;
        formData.append("folder", "images");
        if (type === "file") {
            newForm[i][name] = files[0];
            if (name == "image_hd") {
                formData.append("image", form[i].image_hd);
                try {
                    setHdLoading(true)
                    let resp = await axios.post(
                        `${process.env.REACT_APP_BACKEND_API_BASE_URL}/uploadHdImage`,
                        formData,
                        { headers: { Authorization: `${token}` } }
                    );
                    if (resp.data.success) {
                        setHdLoading(false);
                    }
                    console.log("image_low ==>", resp);
                    newForm[i].image_hd = resp.data.body.filename;
                } catch (error) {
                    setHdLoading(false)
                }

            }
            if (name == "image_low") {
                formData.append("image", form[i].image_low);
                try {
                    debugger
                    setLoading(true);
                    let resp = await axios.post(
                        `${process.env.REACT_APP_BACKEND_API_BASE_URL}/uploadLowImage`,
                        formData,
                        { headers: { Authorization: `${token}` } }
                    );
                    if (resp.data.success) {
                        setLoading(false);
                    }

                    newForm[i].image_low = resp.data.body.filename;

                } catch (error) {
                    setLoading(false)
                }


            }
            if (name == "image") {

                formData.append("image", form[i].image);
                try {
                    setLoading(true);
                    let resp = await axios.post(
                        `${process.env.REACT_APP_BACKEND_API_BASE_URL}/uploadImage`,
                        formData,
                        { headers: { Authorization: `${token}` } }
                    );
                    if (resp.data.success) {
                        setLoading(false);
                    }

                    console.log(resp)

                    newForm[i].image_hd = resp.data.body.hdFilename;
                    newForm[i].image_low = resp.data.body.lowResFilename;

                } catch (error) {
                    setLoading(false)
                }


            }
        } else {
            newForm[i][name] = value;
        }
        setForm(newForm);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        var res = [];
        let token = checkLoginToken()
        let payload = [...form]
        payload.token = token
        debugger
        await dispatch(addImageThunk(payload));


        toast.success("Image created.");
        navigate("/images");
        // console.log("response.payload", res);
    };

    const categoryChange = (e) => {
        let categoryDetail =
            e.target.value && e.target.value != ""
                ? activeCategories.find((ele) => ele._id == e.target.value)
                : null;

    };
    const imageTypes = [
        {
            value: "Phone",
            label: "Phone",
        },
        {
            value: "Tablet",
            label: "Tablet",
        },
        {
            value: "Desktop",
            label: "Desktop",
        },
    ];
    const getPacksList = () => {
        let tempOptions = [];

        packs.map((item) => {
            tempOptions.push({ name: item.pack_name, id: item._id });
        });

        setOptions([...tempOptions]);
    };
    useEffect(() => {
        getPacksList();
    }, []);
    const getSelectedPack = (packSelected) => {
        console.log(packSelected, "packSelected");
        setAssignedPack([...packSelected]);
    };
    return (
        <div
            className="add_edit_box add_edit_box_image"
            style={{ marginBottom: "35px" }}
        >
            <h2>
                Add Image Details
                <button className="btn add_btn" onClick={handleAddMore}>
                    Add More
                </button>
            </h2>
            <form className="add-category" onSubmit={handleSubmit}>
                {form.map((item, i) => {
                    return (
                        <>
                            <div key={i} className="flex-fg">
                                {/* <div className="form-group cus-img">
                  <label>HD Image</label>

                  <input
                    type="file"
                    name="image_hd"
                    required
                    id={i.image_hd}
                    onChange={(e) => handleChange(i, e)}
                    className="form-control"
                  />
                  {hdLoading ? <Loader content="Image Uploading..." /> : ""}
                </div>
                <div className="form-group cus-img">
                  <label>Low Image</label>

                  <input
                    type="file"
                    name="image_low"
                    required
                    id={i.image_low}
                    onChange={(e) => handleChange(i, e)}
                    className="form-control"
                  />
                  {loading ? <Loader content="Image Uploading..." /> : ""}
                </div> */}

                                <div className="form-group cus-img">
                                    <label>Image</label>

                                    <input
                                        type="file"
                                        name="image"
                                        required
                                        id={i.image}
                                        onChange={(e) => handleChange(i, e)}
                                        className="form-control"
                                    />
                                    {loading ? <Loader content="Image Uploading..." /> : ""}
                                </div>

                                <div className="form-group cus-img">
                                    <label>Select Category</label>
                                    <div className="select_icon">
                                        <DownArrowSvg />
                                        <select
                                            name="category_id"
                                            className={"form-control"}
                                            value={i.category_id}
                                            onChange={(e) => {
                                                handleChange(i, e);
                                                categoryChange(e);
                                            }}
                                            required
                                        >
                                            <option value="">Select a category </option>

                                            {activeCategories &&
                                                activeCategories.map((ele) => {
                                                    return <option value={ele._id}>{ele.name}</option>;
                                                })}
                                        </select>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label>Select Types</label>
                                    <div className="select_icon">
                                        <DownArrowSvg />
                                        <select
                                            name="type"
                                            className={"form-control"}
                                            // placeholder="
                                            onChange={(e) => {
                                                handleChange(i, e);
                                            }}
                                            value={item.type}
                                            required
                                        >
                                            <option value="" disabled>
                                                Select Type
                                            </option>

                                            {imageTypes.length > 0 &&
                                                imageTypes.map((ele) => {
                                                    return <option value={ele.value}>{ele.label}</option>;
                                                })}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group tags">
                                    <label>Hashtags</label>

                                    <StringArrayInput
                                        values={enteredHashTags}
                                        onChange={setEnteredHashTags}
                                    />
                                </div>
                                <div className="form-group cus-img">
                                    <label>Publisher</label>
                                    <div className="select_icon">
                                        <DownArrowSvg />
                                        <select
                                            name="publisher"
                                            className={"form-control"}
                                            value={i.publisher}
                                            onChange={(e) => {
                                                handleChange(i, e);
                                                categoryChange(e);
                                            }}
                                        >
                                            <option value="">Select </option>

                                            {publishers &&
                                                publishers.map((ele) => {
                                                    return <option value={ele.name}>{ele.name}</option>;
                                                })}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group cus-img">
                                    <label>Title</label>
                                    <input
                                        type="text"
                                        name="title"
                                        required
                                        placeholder="Title"
                                        className={"form-control"}
                                        value={i.title}
                                        onChange={(e) => handleChange(i, e)}
                                    />
                                </div>
                                <div className="form-group cus-img">
                                    <label>
                                        Description
                                        <div
                                            style={{
                                                color: "grey",
                                                fontSize: "15px",
                                                marginLeft: "6px",
                                            }}
                                        >
                                            {" "}
                                            (optional)
                                        </div>
                                    </label>
                                    <input
                                        type="text"
                                        name="description"
                                        placeholder="Description"
                                        className={"form-control"}
                                        value={i.description}
                                        onChange={(e) => handleChange(i, e)}
                                    />
                                </div>
                                <div className="form-group cus-img">
                                    <label style={{ display: "flex" }}>
                                        Url
                                        <div
                                            style={{
                                                color: "grey",
                                                fontSize: "15px",
                                                marginLeft: "6px",
                                            }}
                                        >
                                            {" "}
                                            (optional)
                                        </div>
                                    </label>
                                    <input
                                        type="text"
                                        name="url"
                                        placeholder="Url"
                                        className={"form-control"}
                                        value={i.url}
                                        onChange={(e) => handleChange(i, e)}
                                    />
                                </div>

                                <div className="form-group cus-img">
                                    <label style={{ display: "flex" }}>
                                        Image variant id
                                        <div
                                            style={{
                                                color: "grey",
                                                fontSize: "15px",
                                                marginLeft: "6px",
                                            }}
                                        >
                                            {" "}
                                            (optional)
                                        </div>
                                    </label>
                                    <input
                                        type="text"
                                        name="image_virent_id"
                                        placeholder="Image Virent_id"
                                        className={"form-control"}
                                        value={i.image_virent_id}
                                        onChange={(e) => handleChange(i, e)}
                                    />
                                </div>
                                <div className="form-group cus-img">
                                    <label style={{ display: "flex" }}>
                                        Enter Price
                                        <div
                                            style={{
                                                color: "grey",
                                                fontSize: "15px",
                                                marginLeft: "6px",
                                            }}
                                        >
                                            {" "}
                                            (optional)
                                        </div>
                                    </label>
                                    <input
                                        type="number"
                                        name="price"
                                        placeholder="Enter Price"
                                        className={"form-control"}
                                        value={i.price}
                                        onChange={(e) => handleChange(i, e)}
                                    />
                                </div>

                                <div style={{width:'100%',display:'flex',justifyContent:'flex-end'}}>
                                    {form.length > 1 ? (
                                        <button
                                            className="btn add_btn"
                                            onClick={() => removeItem(i)}
                                        >
                                            Remove
                                        </button>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </>
                    );
                })}

                <button
                    className={`btn add_btn `}
                    style={{ margin: "5px 0 35px", width: "180px" }}
                    type="submit"
                    disabled={loading}
                >
                    Submit
                </button>
            </form>
        </div>
    );
}
