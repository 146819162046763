import {
    Grid, Text,
} from "@mantine/core";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import axios from "axios";
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as DownArrowSvg } from "../../images/down-arrow-6402.svg"
import { setIsLoggedIn } from "../../Redux/userSlice";
import { Toggle } from "rsuite";


const imagefolder = "users"

const AddEditMain = () => {
    const location = useLocation();
    const navigate = useNavigate()

    const dispatch = useDispatch()

    const [addMode, setAddMode] = useState(true)



    function logoutUser() {
        dispatch(setIsLoggedIn());
        localStorage.removeItem("currentToken");
        localStorage.removeItem("userId");
        navigate("/login")
    }

    function checkLoginToken() {
        let token = localStorage.getItem("currentToken")
        if (!token) {
            logoutUser()
        }
        else {
            return token
        }
    }


    const [dataVal, setDataVal] = useState({
        first_name: "",
        last_name: "",
        email: "",
        isSubscriber: false,
        username: "",
        password: "",
        dob: "",
        gender: "",
        country: "",
        image: "",
        _id: ""

    })



    useEffect(() => {
        if (location && location.state && Object.entries(location.state).length > 0) {
            debugger
            setDataVal({
                first_name: location.state.first_name || "",
                last_name: location.state.last_name || "",
                username: location.state.username || "",
                password: '',
                email: '',
                isSubscriber: location.state.isSubscriber,
                dob: location.state.dob || "",
                image: location.state.image || "",
                gender: location.state.gender || "",
                country: location.state.country || '',
                _id: location.state._id || ""
            })
            setAddMode(false)
        }
    }, [])

    // const validationSchema = Yup.object().shape({
    //     name: Yup.string().required("Name is required"),
    //     category_id: Yup.string().trim().required("Category is required"),
    //     ...(addMode ? { image: Yup.string().required("Image is required") } : {}),
    // });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            first_name: dataVal.first_name,
            last_name: dataVal.last_name,
            email: dataVal.email,
            username: dataVal.username,
            isSubscriber: dataVal.isSubscriber,
            password: dataVal.password,
            dob: dataVal.dob,
            image: dataVal.image,
            gender: dataVal.gender,
            country: dataVal.country,
            _id: dataVal._id

        },

        onSubmit: async (data, { resetForm }) => {

            await handleCreateUser(data)
            if (addMode) {
                setFile(null)
                setFileDataURL(null)

                // resetForm()
                // navigate("/users")
            }
        }
    });


    const [file, setFile] = useState<any>(null);
    const [fileDataURL, setFileDataURL] = useState(null);

    const changeHandler = (e: any) => {
        const file = e.target.files[0];

        setFile(file);
    }

    useEffect(() => {
        let fileReader: any, isCancel = false;
        if (file) {
            fileReader = new FileReader();
            fileReader.onload = (e: any) => {
                const { result } = e.target;
                if (result && !isCancel) {
                    setFileDataURL(result)
                }
            }
            fileReader.readAsDataURL(file);
        }
        return () => {
            isCancel = true;
            if (fileReader && fileReader.readyState === 1) {
                fileReader.abort();
            }
        }

    }, [file]);


    const handleCreateUser = async (data: any) => {
        try {
            let token = checkLoginToken()

            let payload: any = { ...data }
            payload.token = token
            payload.is_image_uploaded = 0

            if (file) {
                let formData = new FormData()

                formData.append("folder", imagefolder);

                formData.append("image", file);

                let resp = await axios.post(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/uploadImage`, formData, { 'headers': { 'Authorization': `${token}` } })
                if (resp.statusText == "OK") {
                    let filename = resp.data.body.filename;
                    payload.image = filename;
                    payload.is_image_uploaded = 1
                }
            }
            else {
                payload.image = data.image
            }

            if (dataVal._id) payload._id = dataVal._id;

            let response: any = await axios.post(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/profileUpdate`,
                payload, { 'headers': { 'Authorization': `${token}` } })
            console.log("dasdadfasfafdsf", response);


            if (response.status === 200) {
                toast.success(response.data.message)
                navigate("/users")
            } else {
                toast.error("Something went wrong.")
            }


        } catch (err) {
            toast.error(err.response.data.message)
            console.log({err});
            navigate("/addedituser")
        }
    };

// console.log("sajdghjasdgfyugfewf",formik.values.isSubscriber);

    return (
        <div className="main_layout">

            <h2>
                {addMode ? "Add" : "Edit"} user
            </h2>
            <div className="add_edit_box">
                <form className='add-category' onSubmit={formik.handleSubmit}>
                    <div className="flex-fg">
                        <div className='form-group'>
                            <label>First Name</label>
                            <input
                                name="first_name"
                                type="text"
                                placeholder="Enter First Name"
                                className={
                                    'form-control' +
                                    (formik.errors.first_name && formik.touched.first_name
                                        ? ' is-invalid'
                                        : '')
                                }
                                onChange={formik.handleChange}
                                value={formik.values.first_name}
                            />
                            {/* <div className="invalid-feedback">
                                  {formik.errors.name && formik.touched.name
                                      ? formik.errors.name
                                      : null}
                              </div> */}
                        </div>

                        <div className='form-group'>
                            <label>Last Name</label>
                            <input
                                name="last_name"
                                type="text"
                                placeholder="Enter Last Name"
                                className={
                                    'form-control' +
                                    (formik.errors.last_name && formik.touched.last_name
                                        ? ' is-invalid'
                                        : '')
                                }
                                onChange={formik.handleChange}
                                value={formik.values.last_name}
                            />
                            {/* <div className="invalid-feedback">
                                  {formik.errors.name && formik.touched.name
                                      ? formik.errors.name
                                      : null}
                              </div> */}
                        </div>



                        <div className='form-group'>
                            <label>Image</label>
                            <input
                                name="image"
                                type="file"
                                value={addMode ? formik.values.image : ""}
                                className={
                                    'form-control'
                                }
                                accept='.png, .jpg, .jpeg'
                                onChange={(e: any) => {
                                    formik.handleChange(e)
                                    changeHandler(e)
                                }}
                            />
                            {/* <div className="invalid-feedback">
                                  {formik.errors.image && formik.touched.image
                                      ? formik.errors.image
                                      : null}
                              </div> */}
                        </div>

                        {fileDataURL ?
                            <p className="img-preview-wrapper">
                                {
                                    <img src={fileDataURL} style={{ height: "80px" }} alt="preview" />
                                }
                            </p> : null}

                        {(!fileDataURL && dataVal.image != '') ?
                            <p className="img-preview-wrapper">
                                {
                                    <img src={dataVal.image} style={{ height: "80px" }} alt="preview" />
                                }
                            </p> : null}

                        {
                            addMode ? (
                                <div className='form-group'>
                                    <label>Email</label>
                                    <input
                                        name="email"
                                        type="text"
                                        placeholder="Enter Email"
                                        className={
                                            'form-control' +
                                            (formik.errors.email && formik.touched.email
                                                ? ' is-invalid'
                                                : '')
                                        }
                                        onChange={formik.handleChange}
                                        value={formik.values.email}
                                    />
                                    {/* <div className="invalid-feedback">
              {formik.errors.image && formik.touched.image
                  ? formik.errors.image
                  : null}
          </div> */}
                                </div>
                            ) : ""
                        }

                        <div className='form-group'>
                            <label>User name</label>
                            <input
                                name="username"
                                type="text"
                                placeholder="Enter Name"
                                className={
                                    'form-control' +
                                    (formik.errors.username && formik.touched.username
                                        ? ' is-invalid'
                                        : '')
                                }
                                onChange={formik.handleChange}
                                value={formik.values.username}
                            />
                            {/* <div className="invalid-feedback">
                                  {formik.errors.image && formik.touched.image
                                      ? formik.errors.image
                                      : null}
                              </div> */}
                        </div>
                        <div className='form-group'>
                            <label>Password</label>
                            <input
                                name="password"
                                type="password"
                                placeholder="Enter Password"
                                className={
                                    'form-control' +
                                    (formik.errors.password && formik.touched.password
                                        ? ' is-invalid'
                                        : '')
                                }
                                onChange={formik.handleChange}
                                value={formik.values.password}
                            />
                            {/* <div className="invalid-feedback">
                                  {formik.errors.image && formik.touched.image
                                      ? formik.errors.image
                                      : null}
                              </div> */}
                        </div>
                        <div className='form-group'>
                            <label>Subscriber</label>
                            <div style={{ float: "left" }}>
                                <label className="switch1">
                                    <input type="checkbox" name="isSubscriber" checked={formik.values.isSubscriber ? true : false} onChange={formik.handleChange}  
                                          />
                                    <span className="slider round"></span>
                                </label>
                            </div>

                        </div>
                    </div>

                    <button type="submit" className='btn main_btn1 text-white mt-4'>Submit</button>
                </form>
            </div>
        </div>
    );
};

export default AddEditMain;
