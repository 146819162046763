import React from 'react'
import Cards from './Cards'
import { useEffect, useState } from "react";
import { useMediaQuery } from '@mui/material';
import { Container } from '@mantine/core';
import { userSelector } from '../../Redux/Selectors/userSelector';
import { useDispatch, useSelector } from 'react-redux';
import { imageSelector } from '../../Redux/Selectors/imageSelector';
import { categorySelector } from '../../Redux/Selectors/categorySelector';
import { useNavigate } from 'react-router';
import { purchaseSelector } from '../../Redux/Selectors/purchaseSelector';
import { setIsLoggedIn } from '../../Redux/userSlice';
import { getPurchaseDetailTransactions } from '../../Redux/Thunks/purchaseDetails';
import { toast } from 'react-toastify';
// import {} from 'rsuite/'
import DateRangePicker from 'rsuite/DateRangePicker';
import "../../style.css"
import ReactPaginate from 'react-paginate';
import { CSVLink } from "react-csv";
import { getCategoriesThunk } from '../../Redux/Thunks/categoryThunks';
import { getSubCategoriesThunk } from '../../Redux/Thunks/subcategoryThunk';
import { getUsersThunk } from '../../Redux/Thunks/userThunk';
import { getImagesThunk } from '../../Redux/Thunks/imageThunk';
// import { any } from 'prop-types';

export default function Main() {






  const imagefolder = "images"
  const page: any = process.env.REACT_APP_PAGE
  const limit: any = process.env.REACT_APP_LIMIT



  const { users, totalUsers } = useSelector(userSelector)
  const { images, totalImages } = useSelector(imageSelector)
  const { categories } = useSelector(categorySelector)

  const [selectedPage, setSelectedPage] = useState<any>(parseInt(page))
  const [datePikcer, setDatePicker] = useState<any>({
    fromDate: "",
    toDate: ""
  })
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { totalTransactions,
    transactions, }: any = useSelector(purchaseSelector)

  const [data, setData] = useState([
    {
      label: "Users",
      value: totalUsers
    },
    {
      label: "Images",
      value: images.length
    },
    {
      label: "Categories",
      value: categories.length
    },
    

  ])
  const midScreen = useMediaQuery("(min-width: 1300px)");
  const largeScreen = useMediaQuery("(min-width: 1750px)");
  function logoutUser() {
    dispatch(setIsLoggedIn());
    localStorage.removeItem("currentToken");
    localStorage.removeItem("userId");
    navigate("/login")
  }

  function checkLoginToken() {
    let token = localStorage.getItem("currentToken")
    if (!token) {
      logoutUser()
    }
    else {
      return token
    }
  }
  useEffect(() => {

    getData({
      page: selectedPage,
      limit: limit
    })
  }, [])
  async function getData(data: any) {
    let token = checkLoginToken()
    await dispatch(getCategoriesThunk({
      page: data.page,
      limit: data.limit,
      token: token
    }))
    await dispatch(getSubCategoriesThunk({
      page: data.page,
      limit: data.limit,
      token: token
    }))
    await dispatch(getUsersThunk({
      page: data.page,
      limit: data.limit,
      token: token
    }))
    await dispatch(getImagesThunk({
      page: data.page,
      limit: data.limit,
      token: token
    }))
    let response: any = await dispatch(getPurchaseDetailTransactions(
      {
        page: data.page,
        limit: data.limit,
        token: token
      }
    ))
    if (response.payload.response && response.payload.response.status) {
      if (response.payload.response.status == 401) {
        logoutUser()
      }
      toast.error(response.payload.response.data.message)
    }
    else {
      pageCounterr()
    }
  }



  const rows = transactions && transactions.map((row: any, index: any) =>


  (


    <tr key={row._id}>
      <td>{((selectedPage - 1) * limit) + index + 1}.</td>

      <td>{row.image_id ?
        <img src={(process.env.REACT_APP_BACKEND_BASE_URL + `uploads/${imagefolder}/` + row.image_id.image_hd)} alt={row.image_id.image_hd} height="80px" />

        : "Not found"}
      </td>

      <td>{row?.image_id?.title ? row?.image_id?.title : ""}</td>

      <td>{row?.amount}</td>

      <td>{row?.user_id?.first_name}</td>
      <td>{row?.createdAt.split("T")[0]}</td>

    </tr>
  ));




  const [pageCount, setPageCount] = useState(1);

  const pageCounterr = () => {
    const pageCounter: any = Math.ceil(parseInt(totalTransactions) / parseInt(limit))
    setPageCount(pageCounter)
  }
  const handlePageClick = async (data: any) => {
    setSelectedPage(+data.selected + 1)
    await getData({ page: +data.selected + 1, limit: 10 })
  }
  useEffect(() => {
    pageCounterr()
  }, [totalTransactions])

  useEffect(() => {
    if ((!transactions || transactions.length == 0) && selectedPage > 1) {
      setSelectedPage(selectedPage - 1)

      getData({
        page: selectedPage - 1,
        limit: limit
      })

    }
  }, [transactions])

  const headers: any = [
    { label: "Image Id", key: "image_id" },
    { label: "Image Name", key: "image_name" },
    { label: "Amount", key: "amount" },
    { label: "User", key: "user" },
    { label: "Puchase Date", key: "purchase_date" }

  ];


  const filteredData = transactions.filter((item: any) => {
    // const createdAtDate = new Date(item.createdAt);
    return item.createdAt.slice(0, 10) >= datePikcer.fromDate.slice(0, 10) && item.createdAt.slice(0, 10) <= datePikcer.toDate.slice(0, 10);
  });



  const dataForCsv = filteredData.map((item: any) => {
    return {
      image_id: item.image_id._id,
      image_name: item.image_id.title,
      amount: item.amount,
      user: `${item.user_id.first_name} ${item.user_id.last_name}`,
      purchase_date: item.createdAt.split("T")[0]
    }

  })


  const csvReport = {
    data: dataForCsv,
    headers: headers,
    filename: 'purchase-details.csv',

  };


  const datePikcerDate = (value: any) => {

    setDatePicker({
      fromDate: value[0].toISOString(),
      toDate: value[1].toISOString()
    })
  }



  return (
    <>
      {/* main dashbord section */}
      <div className="main_layout dashboard_main_layout">
        <h2>Dashboard</h2>
        <div className="dashboard_flex">

          {
            data.map((item) => {
              return (
                <>
                  <div className='dashboard_box'>
                    <Cards items={item} />
                  </div>
                </>
              )
            })
          }
        </div>
        <div style={{ marginTop: "40px" }}>
          <h2>Purchase Details
          </h2>
          <div className='filter_head'>
            <DateRangePicker format={`yyyy-MM-dd`} onChange={(value) => datePikcerDate(value)} />

            <CSVLink {...csvReport}><button className='btn export_btn'>Export</button></CSVLink>

          </div>
          {/* image section */}

          <div className="table-responsive">
            <table>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Image</th>
                  <th>Image Name</th>
                  <th>Amount</th>
                  <th>User</th>
                  <th>Puchase Date</th>

                  {/* <th>Actions</th> */}
                </tr>
              </thead>
              <tbody>
                {(rows && rows.length >= 0) && (
                  rows
                )
                }
              </tbody>
            </table>
          </div>



          {
            (transactions && transactions.length > 0) && (
              <ReactPaginate

                previousLabel={"<"}
                nextLabel={">"}
                //    breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"pagination_prev"}
                previousLinkClassName={"page-link"}
                nextClassName={"pagination_next"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                forcePage={selectedPage - 1}
              />
            )
          }

        </div>
      </div>
    </>
  )
}
