import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { Toggle } from 'rsuite';
import { ReactComponent as DeleteSvg } from '../../images/delete-svgrepo-com.svg'
import { ReactComponent as EditSvg } from '../../images/edit-button-svgrepo-com.svg'
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router';
import { imageSelector } from '../../Redux/Selectors/imageSelector';
import { setIsLoggedIn } from '../../Redux/userSlice';
import { getAllActiveCategoriesThunk } from '../../Redux/Thunks/subcategoryThunk';
import { toast } from 'react-toastify';
import { getImagesThunk } from '../../Redux/Thunks/imageThunk';
import { getImageDetailsThunk } from '../../Redux/Thunks/imageThunk';
import { updateValidationError, imageEmpty } from '../../Redux/imageSlice';
import { deleteImageThunk } from '../../Redux/Thunks/imageThunk';
import axios from 'axios';
import { Center } from '@mantine/core';
import AlertDialog from '../Dialog/Alert';
import { HashLoader } from 'react-spinners';


interface Data {
    id: number;
    calories: number;
    carbs: number;
    isPremium: boolean
    fat: number;
    name: string;
    protein: number;
}


const imagefolder = "images"


type Order = 'asc' | 'desc';


interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: readonly any[] = [
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'HD Resolution Image',
    },
    {
        id: 'calories',
        numeric: true,
        disablePadding: false,
        label: 'Low Resolution Image',
    },
    {
        id: 'Publisher',
        numeric: true,
        disablePadding: false,
        label: 'Publisher',
    },
    {
        id: 'Featured',
        numeric: true,
        disablePadding: false,
        label: 'Featured',
    },
    {
        id: 'isPremium',
        numeric: true,
        disablePadding: false,
        label: 'Premium',
    },
    {
        id: 'Created At',
        numeric: true,
        disablePadding: false,
        label: 'Created At',
    },
    {
        id: 'Updated At',
        numeric: true,
        disablePadding: false,
        label: 'Updated At',
    },
    {
        id: 'Actions',
        numeric: true,
        disablePadding: false,
        label: 'Actions',
    },
];

interface EnhancedTableProps {
    numSelected: number;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, numSelected, rowCount, } =
        props;


    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                    >

                        {headCell.label}

                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
interface EnhancedTableToolbarProps {
    numSelected: number;
}

export default function Main() {
    const pageServer: any = process.env.REACT_APP_PAGE

    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('calories');
    const [selected, setSelected] = React.useState<readonly number[]>([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [isSingleImage, setSingleImage] = useState(false);

    const limit: any = 50

    const [selectedPage, setSelectedPage] = useState<any>(page)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { images, totalImages }: any = useSelector(imageSelector)
    const [toggle, setToggle] = useState<any>(true)
    const [showCheckBox, setshowCheckBox] = useState<any>(false)
    const [feactureUpdate, setFeactureUpdate] = useState<any>(toggle)
    // const [imagesIds, setImagesIds] = useState<any>([])
    const [isLoading, setLoading] = useState(false);
    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = images.map((n: any) => n._id);
            setSelected(newSelected);
            console.log('selected', selected)
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: readonly number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
        console.log('selected2', selected)

    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty images.
    const emptyRows =
        page > 0 ? Math.max(0, (page + 1) * rowsPerPage - images.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            [...images]
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
        [order, orderBy, page, rowsPerPage],
    );
    function logoutUser() {
        dispatch(setIsLoggedIn());
        localStorage.removeItem("currentToken");
        localStorage.removeItem("userId");
        navigate("/login")
    }

    function checkLoginToken() {
        let token = localStorage.getItem("currentToken")
        if (!token) {
            logoutUser()
        }
        else {
            return token
        }
    }


    useEffect(() => {
        console.log('response')

        async function getData() {
            let token = checkLoginToken()

            let response: any = await dispatch(getAllActiveCategoriesThunk({ token: token }))
            if (response.payload.response && response.payload.response.status) {
                if (response.payload.response.status == 401) {
                    logoutUser()
                }
                toast.error(response.payload.response.data.message)
            }
        }
        getData()
    }, [])

    async function getData(data: any) {
        let token = checkLoginToken()
        setLoading(true)
        debugger
        let response: any = await dispatch(getImagesThunk(
            {
                page: data.page,
                limit: data.limit,
                token: token
            }
        ))
        console.log('response', response, images, {
            page: data.page,
            limit: data.limit,
            token: token
        })
        debugger
        if (response.payload.response && response.payload.response.status) {
            setLoading(false)
            if (response.payload.response.status == 401) {
                setLoading(false)
                logoutUser()
            }
            toast.error(response.payload.response.data.message)
        }
        else {
            setLoading(false)
            // pageCounterr()
        }
    }

    useEffect(() => {
        getData({
            page: selectedPage + 1,
            limit: limit
        })
    }, [feactureUpdate])

    const handleEdit = async (e: any, data: any) => {
        e.preventDefault()
        let token = checkLoginToken()
        let response: any = await dispatch(getImageDetailsThunk({ token: token, image_id: data._id }))
        if (response.payload.response && response.payload.response.status) {
            if (response.payload.response.status == 401) {
                logoutUser()
            }
            toast.error(response.payload.response.data.message)
        }
        dispatch(updateValidationError(false))
        navigate("/image/addedit", { state: data })
    }

    const [deleteDialog, setDeleteDialog] = useState(false)
    const [elementId, setElementId] = useState("")

    const handleDeleteModal = async (e: any, _id: any) => {
        setDeleteDialog(true)
        setElementId(_id)
    }
    const deleteMultiples = async (bool: any) => {
        let token = checkLoginToken()
        let url = `${process.env.REACT_APP_BACKEND_API_BASE_URL}/image/deleteMultiple`
        let body = { images: selected }

        const res = await axios.delete(url,
            { headers: { "Authorization": `${token}` }, data: body })

        getData({
            page: selectedPage + 1,
            limit: limit
        })
        if (res.status == 200) {
            setDeleteDialog(bool)
            window.location.reload()
        }
    }

    const handleDeleteImage = async (id: any) => {
        try {
            let token = checkLoginToken()

            let payload = {
                _id: id,
                token: token
            }
            let response: any = await dispatch(deleteImageThunk(payload))

            if (response.payload.response && response.payload.response.status) {
                if (response.payload.response.status == 401) {
                    logoutUser()
                }
                toast.error(response.payload.response.data.message)
            }


            if (response.payload.data && response.payload.data.success) {
                toast.success(response.payload.data.message)
                debugger
                await getData({
                    page: selectedPage + 1,
                    limit: limit
                })
                setDeleteDialog(false)
                window.location.reload();
                setElementId("")
                setSingleImage(false)
            }
        } catch (err) {
            console.log(err);
        }
    };

    const updateFeature = async (id: any, bool: boolean) => {
        let token = checkLoginToken()

        // setToggle(!toggle)
        const res = await axios.put(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/image/featureUpdate/${id}`,
            { isFeatured: bool }, { headers: { "Authorization": `${token}` } })
        console.log(res)
        getData({
            page: selectedPage + 1,
            limit: limit
        })
    }
    const updatePremium = async (id: any, bool: boolean) => {
        let token = checkLoginToken()

        // setToggle(!toggle)
        const res = await axios.put(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/image/premiumUpdate/${id}`,
            { isPremium: bool }, { headers: { "Authorization": `${token}` } })
        console.log(res)
        getData({
            page: selectedPage + 1,
            limit: limit
        })
    }

    return (
        !isLoading ? (<div className="main_layout" style={{ backgroundColor: '#F5F6FA', paddingRight: '3%' }}>
            <h2>Images
                <div>
                    <button color="warning" className="btn add_btn_delete" onClick={(e) => {
                        handleDeleteModal(e, "")
                        //   deleteMultiples(false)
                    }}>Delete Selected</button>
                    <button color="warning" className="btn add_btn_add" onClick={() => {
                        dispatch(imageEmpty())
                        dispatch(updateValidationError(true))
                        navigate("/image/addedit")
                    }}>+ image</button>
                </div>
            </h2>
            <Box sx={{ width: '100%' }}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <TableContainer>
                        <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size={dense ? 'small' : 'medium'}
                        >
                            <EnhancedTableHead
                                numSelected={selected.length}
                                onSelectAllClick={handleSelectAllClick}
                                rowCount={images.length}
                            />
                            <TableBody>
                                {visibleRows.map((row, index) => {
                                    const isItemSelected = selected.includes(row._id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            //  role="checkbox"
                                            //  aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row._id}
                                            selected={isItemSelected}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    onClick={(event) => handleClick(event, row._id)}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="normal"
                                                align="center"
                                            >
                                                <img style={{ borderRadius: '10px' }} src={row.url} alt={row.url} height="60px" width='60px' />


                                            </TableCell>
                                            <TableCell component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="normal" align="center"> <img style={{ borderRadius: '10px' }} src={row.url_low} alt={row.url_low} height="60px" width='60px' /></TableCell>
                                            <TableCell align="center">{row.publisher}</TableCell>

                                            <TableCell align="center"> <Toggle
                                                onChange={(value) => { updateFeature(row._id, !row.isFeatured) }}
                                                defaultChecked={row.isFeatured}
                                            /></TableCell>  <TableCell align="center"> <Toggle
                                                onChange={(value) => { updatePremium(row._id, !row.isPremium) }}
                                                defaultChecked={row.isPremium}
                                            /></TableCell>
                                            <TableCell align="center">{row.createdAt.split("T")[0]}</TableCell>
                                            <TableCell align="center">{row.updatedAt.split("T")[0]}</TableCell>
                                            <TableCell align="center">
                                                <EditSvg width={'15px'} height={"15px"} style={{ cursor: "pointer" }} onClick={(e) => handleEdit(e, row)} />
                                                <DeleteSvg width={'15px'} height={"15px"} style={{ cursor: "pointer" }} onClick={(e) => {
                                                    setSingleImage(true)

                                                    handleDeleteModal(e, row._id)
                                                }} />
                                            </TableCell>


                                        </TableRow>
                                    );
                                })}
                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height: (dense ? 33 : 53) * emptyRows,
                                        }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={images.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
                <AlertDialog open={deleteDialog} setOpen={setDeleteDialog} handleAgree={() => {
                    isSingleImage ? handleDeleteImage(elementId) :
                        deleteMultiples(false)
                }} />
            </Box>
        </div>) : (
            <div className="spinner" >

                <HashLoader color="#025364" size={75} loading={true} />
            </div>
        )
    );
}