import {
  Grid, Text,
} from "@mantine/core";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import axios from "axios";
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as DownArrowSvg } from "../../images/down-arrow-6402.svg"
import { setIsLoggedIn } from "../../Redux/userSlice";
import { addPublishersThunk } from "../../Redux/Thunks/publisherThunk";



const AddEditMain = () => {
  const location = useLocation();
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const [addMode, setAddMode] = useState(true)

  function logoutUser() {
    dispatch(setIsLoggedIn());
    localStorage.removeItem("currentToken");
    localStorage.removeItem("userId");
    navigate("/login")
  }

  function checkLoginToken() {
    let token = localStorage.getItem("currentToken")
    if (!token) {
      logoutUser()
    }
    else {
      return token
    }
  }


  const [dataVal, setDataVal] = useState({
    name: "",

    _id: ''
  })


  useEffect(() => {
    if (location && location.state && Object.entries(location.state).length > 0) {
      setDataVal({
        name: location.state.name || "",

        _id: location.state._id || ''
      })
      setAddMode(false)
    }
  }, [])

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),

  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: dataVal.name,
      _id: dataVal._id

    },
    validationSchema,
    onSubmit: async (data, { resetForm }) => {
      console.log(data);
      const token = checkLoginToken()
      let payload =
      {
        ...data,
        token
      }
      const add: any = await dispatch(addPublishersThunk(payload))
      console.log("ASDASDASD", add?.payload?.status);
      if (add?.payload?.status == 200) {

        toast.success(add.payload.data.message)
        navigate('/publisher')
      } else {
        toast.error("Something went wrong")
      }



    }
  });




  return (
    <div className="main_layout">

      <h2>
        {addMode ? "Add" : "Edit"} Publisher
      </h2>
      <div className="add_edit_box">
        <form className='add-category' onSubmit={formik.handleSubmit}>
          <div className="flex-fg">
            <div className='form-group'>
              <label>Publisher Name</label>
              <input
                name="name"
                type="text"
                placeholder="Enter Name"
                className={
                  'form-control' +
                  (formik.errors.name && formik.touched.name
                    ? ' is-invalid'
                    : '')
                }
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              <div className="invalid-feedback">
                {formik.errors.name && formik.touched.name
                  ? formik.errors.name
                  : null}
              </div>
            </div>


          </div>

          <button type="submit" className='btn main_btn1 text-white mt-4'>Submit</button>
        </form>
      </div>
    </div>
  );
};

export default AddEditMain;
