import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { Toggle } from 'rsuite';
import { ReactComponent as DeleteSvg } from '../../images/delete-svgrepo-com.svg'
import { ReactComponent as EditSvg } from '../../images/edit-button-svgrepo-com.svg'
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router';
import { imageSelector } from '../../Redux/Selectors/imageSelector';
import { setIsLoggedIn } from '../../Redux/userSlice';
import { toast } from 'react-toastify';
import { getImagesThunk } from '../../Redux/Thunks/imageThunk';
import { getImageDetailsThunk } from '../../Redux/Thunks/imageThunk';
import { updateValidationError, imageEmpty } from '../../Redux/imageSlice';
import { deleteImageThunk } from '../../Redux/Thunks/imageThunk';
import axios from 'axios';
import { Center } from '@mantine/core';
import AlertDialog from '../Dialog/Alert';
import { deleteCategoryThunk } from '../../Redux/Thunks/categoryThunks';
import { categorySelector } from '../../Redux/Selectors/categorySelector';
import { getCategoriesThunk } from '../../Redux/Thunks/categoryThunks';
import { getUsersThunk } from '../../Redux/Thunks/userThunk';
import { userSelector } from '../../Redux/Selectors/userSelector';
import { string } from 'prop-types';
import { Button } from '@mui/material';
import { HashLoader } from 'react-spinners';


interface Data {
    id: number;
    calories: number;
    carbs: number;
    isPremium: boolean
    fat: number;
    name: string;
    protein: number;
}


const imagefolder = "images"


type Order = 'asc' | 'desc';


interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: readonly any[] = [
    {
        id: 'id',
        numeric: false,
        disablePadding: true,
        label: 'ID',
    },
    {
        id: 'Name',
        numeric: true,
        disablePadding: false,
        label: 'Name',
    },
    {
        id: 'Address',
        numeric: string,
        disablePadding: false,
        label: 'Address',
    },
    {
        id: 'Created At',
        numeric: true,
        disablePadding: false,
        label: 'Date',
    },
    {
        id: 'status',
        numeric: true,
        disablePadding: false,
        label: 'Status',
    },
    {
        id: 'Actions',
        numeric: true,
        disablePadding: false,
        label: 'Actions',
    },
];


function EnhancedTableHead() {


    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'center'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                    >

                        {headCell.label}

                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
interface EnhancedTableToolbarProps {
    numSelected: number;
}

export default function Main() {
    const pageServer: any = process.env.REACT_APP_PAGE

    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('calories');
    const [selected, setSelected] = React.useState<readonly number[]>([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const limit: any = 500

    const [selectedPage, setSelectedPage] = useState<any>(page)


    // const [pageCount, setPageCount] = useState(1);
    const dispatch = useDispatch()



    //const midScreen = useMediaQuery("(min-width: 1300px)");
    // const largeScreen = useMediaQuery("(min-width: 1750px)");
    const [isLoading, setLoading] = useState(false);




    const { users, totalUsers }: any = useSelector(userSelector)

    const navigate = useNavigate()

    async function getData(data: any) {

        setLoading(true)
        let token = localStorage.getItem("currentToken")
        let response: any = await dispatch(getUsersThunk(
            {
                page: data.page,
                limit: data.limit,
                token: token
            }
        ))
        console.log('users', users)
        if (response.payload.response && response.payload.response.status) {
            if (response.payload.response.status == 401) {
                setTimeout(() => {

                    setLoading(false)
                }, 500);
                dispatch(setIsLoggedIn());
                localStorage.removeItem("currentToken");
                localStorage.removeItem("userId");
                navigate("/login")
            }
            toast.error(response.payload.response.data.message)
        }
        else {
            setTimeout(() => {

                setLoading(false)
            }, 500);
            //  pageCounterr()
        }

    }

    useEffect(() => {
        getData({
            page: selectedPage + 1,
            limit: limit
        })
    }, [])
    const handleEdit = async (item: any) => {

        navigate('/addedituser', { state: item })

    }
    const [deleteDialog, setDeleteDialog] = useState(false)
    const [elementId, setElementId] = useState("")

    const handleDeleteModal = async (e: any, _id: any) => {
        setDeleteDialog(true)
        setElementId(_id)
    }
    function logoutUser() {
        dispatch(setIsLoggedIn());
        localStorage.removeItem("currentToken");
        localStorage.removeItem("userId");
        navigate("/login")
    }

    function checkLoginToken() {
        let token = localStorage.getItem("currentToken")
        if (!token) {
            logoutUser()
        }
        else {
            return token
        }
    }
    const handleDeleteUser = async (id: any) => {
        try {

            let token = checkLoginToken()

            let payload = {
                _id: id,
                token: token
            }
            let response: any = await axios.delete(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/delete/${id}`,
                { 'headers': { 'Authorization': `${token}` } })
            console.log("res====>", response);
            if (response.status === 200) {
                toast.success(response.data.message)
                await getData({
                    page: selectedPage + 1,
                    limit: limit
                })

                setDeleteDialog(false)
                window.location.reload()
                setElementId("")
            } else {
                toast.error("Something went wrong.")
            }



        } catch (err) {
            console.log(err);
        }
    };





    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty images.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            [...users]
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
        [order, orderBy, page, rowsPerPage],
    );

    return (
        ! isLoading ? (
        <div className="main_layout" style={{ backgroundColor: '#F5F6FA', paddingRight: '3%' }}>
            <h2>Users List
            <button color="warning" className="btn add_btn_add" onClick={() => {
                navigate("/addedituser")
              }}>+ User</button>
            </h2>
            <Box sx={{ width: '100%' }}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <TableContainer>
                        <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size={dense ? 'small' : 'medium'}
                        >
                            <EnhancedTableHead
                            />
                            <TableBody>
                                {visibleRows.map((row, index) => {
                                    const isItemSelected = selected.includes(row._id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            //  role="checkbox"
                                            //  aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row._id}
                                            sx={{ cursor: 'pointer' }}
                                        >

                                            <TableCell align="center">{row._id}</TableCell>

                                            <TableCell align="center">{`${row.
                                                first_name} ${row.last_name}`}</TableCell>
                                            <TableCell align="center">{row.email}</TableCell>

                                            <TableCell align="center">{row.createdAt.split("T")[0]}</TableCell>
                                            <TableCell align="center">
                                                <Button className="a-dactive" variant="outlined" color={row.status ? "success" : "error"}>
                                                    {row.status ? "Active" : "Inactive"}
                                                </Button></TableCell>
                                            <TableCell align="center">
                                                <EditSvg width={'15px'} height={"15px"} style={{ cursor: "pointer" }} onClick={(e) => handleEdit(row)} />
                                                <DeleteSvg width={'15px'} height={"15px"} style={{ cursor: "pointer" }} onClick={(e) =>
                                                    handleDeleteModal(e, row._id)
                                                } />
                                            </TableCell>


                                        </TableRow>
                                    );
                                })}
                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height: (dense ? 33 : 53) * emptyRows,
                                        }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={users.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
                <AlertDialog open={deleteDialog} setOpen={setDeleteDialog} handleAgree={() => {
                    handleDeleteUser(elementId)
                }} />
            </Box>
        </div> ):(
             <div className="spinner" >
           
             <HashLoader color="#025364" size={75} loading={true} />
           </div>
        )

    );
}